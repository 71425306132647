import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "@/assets/sass/style.css";

// swiper
import "swiper/css";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/thumbs';

// font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, far, fab);


const app = createApp(App);
const pinia = createPinia();
app.component("fa", FontAwesomeIcon);
app.component('VueDatePicker', VueDatePicker);
app.use(pinia);
app.use(router).mount("#app");

router.beforeEach((to, from, next) => {
  if (to.fullPath == "/" || to.fullPath == "/us") {
    if (process.env.NODE_ENV === 'production') {
      fetch('https://geolocation-db.com/json/')
        .then(response => response.json())
        .then(data => {
          const targetPath = data.country_code === 'US' ? '/us' : '/';
          if (to.path !== targetPath) {
            next(targetPath);
          } else {
            next();
          }
        })
        .catch(error => {
          console.error("Error fetching geolocation data:", error);
          next(); // Allow navigation even if there's an error
        });
    } else {
      next();
    }
  }else if(to.fullPath == "/trading-platforms/us" || to.fullPath == "/trading-platforms"){
    if (process.env.NODE_ENV === 'production') {
      fetch('https://geolocation-db.com/json/')
        .then(response => response.json())
        .then(data => {
          const targetPath = data.country_code === 'US' ? '/trading-platforms/us' : '/trading-platforms';
          if (to.path !== targetPath) {
            next(targetPath);
          } else {
            next();
          }
        })
        .catch(error => {
          console.error("Error fetching geolocation data:", error);
          next(); // Allow navigation even if there's an error
        });
    } else {
      next();
    }
  }else{
    next();
  }
});

