<template>
  <div class="header_wrap fixed-top">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <!-- logo -->
        <router-link to="/" class="navbar-brand" @click="mainPage()">
          <img src="@/assets/img/header_logo.svg" alt="header_logo" class="img-fluid header_logo" />
        </router-link>

        <!-- toggle button -->
        <button class="navbar-toggler order-3 order-lg-2" type="button" :class="{ collapsed: !isExpanded }"
          @click="toggleNavbar">
          <div class="navbar-toggler-icon line-1"></div>
          <div class="navbar-toggler-icon line-2"></div>
          <div class="navbar-toggler-icon line-3"></div>
        </button>

        <!-- nav links -->
        <div class="collapse navbar-collapse order-4 order-lg-3" :class="{ show: isExpanded }">
          <ul class="navbar-nav mb-2 mb-lg-0 mx-auto">
            <li v-for="(item, index) in navItems" :key="index" class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                v-if="item.subItems">
                <span>{{ item.title }}</span>
                <fa icon="chevron-down"></fa>
              </a>
              <RouterLink @click="toggleNavbar" class="nav-link" :to="item.link" v-else>
                <span>{{ item.title }}</span>
              </RouterLink>
              <ul class="dropdown-menu" v-if="item.subItems">
                <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
                  <RouterLink @click="toggleNavbar" :to="subItem.link" class="dropdown-item" v-if="!subItem.target">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>{{ subItem.title }}</span>
                  </RouterLink>
                  <a :href="subItem.link"  @click="toggleNavbar" target="_blank" class="dropdown-item" v-else> <svg class="d-lg-none"
                      xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg><span>{{ subItem.title }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <!-- btns group -->
          <div class="btns_group ms-auto order-2 order-lg-4 d-flex d-md-none flex-column mt-3 mt-md-0">
            <a href="https://trader.holaprime.com/en/sign-in" class="dashboard_btn text-decoration-none w-100"
              target="_blank" id="sign_in">
              <span>Login</span>
            </a>
            <a href="https://trader.holaprime.com/en/sign-up"
              class="dashboard_btn dashboard_outline_btn text-decoration-none w-100" aria-current="page" target="_blank"
              id="sign_up">
              <span>Register</span>
            </a>
          </div>
        </div>

        <!-- btns group -->
        <div class="btns_group ms-auto order-2 order-lg-4 d-none d-md-flex me-md-3 me-lg-0">
          <a href="https://trader.holaprime.com/en/sign-in" class="dashboard_btn text-decoration-none" target="_blank"
            id="sign_in">
            <span>Login</span>
          </a>
          <a href="https://trader.holaprime.com/en/sign-up"
            class="dashboard_btn dashboard_outline_btn text-decoration-none" aria-current="page" target="_blank"
            id="sign_up">
            Register
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isExpanded: false,
      navItems: [
        {
          title: "About Us",
          subItems: [
            { title: "Who we are", link: "/about-us" },
            { title: "Our Team", link: "/teams" },
            { title: "Contact Us", link: "/contact-us" },
          ],
        },
        {
          title: "Funding Plans",
          subItems: [
            { title: "Pro Challenges", link: "/pro-challenge" },
            { title: "Prime Challenges", link: "/prime-challenge" },
            { title: "Scaling Plan", link: "/scaling" },
            // { title: "Alpha Prime", link: "/alpha-prime" },
            { title: "Trading Rules", link: "/trading-rules" },
          ],
        },
        {
          title: "Trading",
          subItems: [
            { title: "Leaderboard", link: "/leaderboard" },
            { title: "Transparency Report", link: "/transparency-report" },
            { title: "Platforms", link: "/trading-platforms" },
            { title: "Trading Tools", link: "/trading-tools" },
          ],
        },
        { title: "FAQs", link: "/faq" },
        {
          title: "Academy",
          subItems: [
            { title: "Prime Academy", link: "/prime-academy" },
            { title: "Trading Coaches", link: "/trading-coach" },
            { title: "Blogs", link: "/blogs" },
          ],
        },
        {
          title: "Affiliate",
          subItems: [
            { title: "Partners", link: "/affiliate-program" },
            { title: "FAQs", link: "/faq/affilate" },
            { title: "Login", link: "https://trader.holaprime.com/en/sign-in", target: "blank" },
          ],
        },
      ],
    };
  },
  methods: {
    toggleNavbar() {
      this.isExpanded = !this.isExpanded;
    },
    mainPage() {
      if (this.isExpanded) {
        this.toggleNavbar();
      }
    },
  },
};
</script>