<template>
  <div class="footer">
    <div class="bg_black">
      <div class="container-fluid">
        <div class="main_box">
          <div>
            <RouterLink to="/">
              <img src="@/assets/img/white.svg" alt="footer_logo" class="footer_logo">
            </RouterLink>
            <div class="soical_links d-flex align-items-center">
              <a href="https://www.facebook.com/profile.php?id=61565158992654" target="_blank">
                <fa :icon="['fab', 'facebook']" class="cursor" />
              </a>
              <a href="https://www.instagram.com/holaprime_global" target="_blank">
                <fa :icon="['fab', 'instagram']" class="cursor" />
              </a>
              <a href="https://www.linkedin.com/company/hola-prime/" target="_blank">
                <fa :icon="['fab', 'linkedin']" class="cursor" />
              </a>
              <a href="https://x.com/HolaPrimeGlobal" target="_blank">
                <fa :icon="['fab', 'x-twitter']" class="cursor" />
              </a>
              <a href="https://www.youtube.com/@HolaPrime_Global" target="_blank">
                <fa :icon="['fab', 'youtube']" class="cursor" />
              </a>
            </div>
            <a href="mailto:support@holaprime.com" class="email_link text-decoration-none">Contactus@holaprime.com</a>
          </div>
          <!-- links box -->
          <div class="links_box">
            <!-- About us links group  -->
            <div class="links_group">
              <div class="links_title">About us</div>
              <ul>
                <li>
                  <RouterLink to="/about-us"> Who we are </RouterLink>
                </li>
                <li>
                  <RouterLink to="/teams"> Our Team </RouterLink>
                </li>
                <li>
                  <RouterLink to="/contact-us"> Contact Us </RouterLink>
                </li>
              </ul>
            </div>
            <!-- Funding Plans links group  -->
            <div class="links_group">
              <div class="links_title">Funded</div>
              <ul>
                <li>
                  <RouterLink to="/pro-challenge"> Pro Challenges </RouterLink>
                </li>
                <li>
                  <RouterLink to="/prime-challenge">Prime Challenges </RouterLink>
                </li>
                <li>
                  <RouterLink to="/scaling"> Scaling Plan </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink to="/alpha-prime"> Alpha Prime </RouterLink>
                </li> -->
                <li>
                  <RouterLink to="/trading-rules"> Trading Rules </RouterLink>
                </li>
              </ul>
            </div>
            <!-- Free Resources links group  -->
            <div class="links_group">
              <div class="links_title">Trading</div>
              <ul>
                <li>
                  <RouterLink to="/leaderboard"> Leaderboard </RouterLink>
                </li>
                <li>
                  <RouterLink to="/transparency-report">Transparency Report</RouterLink>
                </li>
                <li>
                  <RouterLink to="/trading-platforms">Platforms</RouterLink>
                </li>
                
                <li>
                  <RouterLink to="/trading-tools">Trading Tools</RouterLink>
                </li>
              </ul>
            </div>
            <!-- Academy links group  -->
            <div class="links_group order-2 order-lg-0">
              <div class="links_title">Academy</div>
              <ul>
                <li><router-link to="/prime-academy">Prime Academy</router-link></li>
                <li>
                  <router-link to="/trading-coach">Trading Coaches</router-link>
                </li>
                <li>
                  <router-link to="/blogs">Blogs</router-link>
                </li>
              </ul>
            </div>
            <!-- FAQs links group  -->
            <div class="links_group order-3 order-lg-0">
              <div>
                <router-link to="/faq" class="links_title">FAQs</router-link>
              </div>
            </div>
            <!-- Affiliate links group  -->
            <div class="links_group order-1 order-lg-0">
              <div class="links_title">Affiliate</div>
              <ul>
                <li><router-link to="/affiliate-program">Partners</router-link></li>
                <li>
                  <router-link to="/faq/affilate">FAQs</router-link>
                </li>
                <li>
                  <a href="https://trader.holaprime.com/en/sign-in" target="_blank">Login</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <h1 class="say_hola_text d-flex justify-content-center gap_20">
          say
          <span class="fw_600 d-flex">h
            <div class="slide-image">
              <img :src="currentImage" alt="hola_logo" class="img-fluid" />
            </div>
            la
          </span>
          to
          <span class="security_gradient_color slide-text">{{
            currentText
          }}</span>
        </h1>

        <div class="bottom_box">
          <div class="mw_746 mb-0">
            <p class="fs_12">
              Risk Warning: Leveraged products such as Forex and CFD trading are
              complex instruments with a high risk of losing money. You must be
              aware of the risks and be willing to accept them to trade in the
              markets.
            </p>
            <p class="fs_12 mb-0">
              Disclaimer: Hola Prime® does not provide services for residents of
              any country where such distribution or use would be contrary to
              local law or regulation. You must be 18 years old, or of legal age
              as determined in your country. Our services are designed to
              evaluate an individual's trading competencies, and outcomes depend
              on the individual's proficiency and adherence to our trading
              program guidelines and goals. Hola Prime® is not a broker and thus
              doesn't receive deposits from its clients. Liquidity providers
              facilitate the technical setup and price feed data for Hola
              Prime's platforms.
            </p>
          </div>
          <div class="copyright_box d-flex flex-column">
            <p class="text-start mb_20 order-2 order-lg-0">
              © 2024 Hola Prime All rights reserved.
            </p>
            <div class="copyright_links flex-wrap gap-3">
              <router-link to="/privacy-policy" class="fs_12" aria-current="page">
                <span>Privacy Policy</span>
              </router-link>
              <router-link to="/terms-conditions" class="fs_12" aria-current="page">
                <span>Terms & Conditions</span>
              </router-link>
              <router-link to="/risk-disclosure" class="fs_12" aria-current="page">
                <span>Risk Disclosure</span>
              </router-link>
              <router-link to="/anti-money-laundering-policy" class="fs_12" aria-current="page">
                <span>Anti-money laundering policy</span>
              </router-link>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg_glow_light"></div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  data() {
    return {
      images: [
        require("@/assets/img/hola_logo_2.png"),
        require("@/assets/img/hola_logo_3.png"),
        require("@/assets/img/hola_logo_4.png"),
        require("@/assets/img/hola_logo_5.png"),
        require("@/assets/img/hola_logo_6.png"),
        require("@/assets/img/hola_logo_7.png"),
        require("@/assets/img/hola_logo_8.png"),
        require("@/assets/img/hola_logo_9.png"),
      ],
      texts: ["security", "reliability", "protection", "results", "precision", "trust", "success", "growth", "honesty"],
      currentIndex: 0,
      currentImage: require("@/assets/img/hola_logo.png"),
      currentText: "security",
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.setupAnimation();
  },
  methods: {
    changeContent() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.currentImage = this.images[this.currentIndex];
      this.currentText = this.texts[this.currentIndex];
    },
    setupAnimation() {
      gsap
        .timeline({ repeat: -1, repeatDelay: 1.5 })
        .to([".slide-image", ".slide-text"], {
          duration: 0.5,
          y: -30, // Slide up
          opacity: 0,
          onComplete: this.changeContent,
        })
        .set([".slide-image", ".slide-text"], { y: 30, opacity: 0 })
        .to([".slide-image", ".slide-text"], {
          duration: 0.5,
          y: 0,
          opacity: 1,
        });
    },
  },
};
</script>
<style scoped></style>
